<template>

  <div class="mx-3 w100">

    <div class="w-100 m-auto item-ali-center" v-if="source !== 'home'">
      <commonBreadcrumbs class="w-100" :items="items" />
    </div>
    <v-divider class="w-100" v-if="source !== 'home'"></v-divider>
    <template v-if="loader">
      <v-progress-linear
          :active="loader"
          color="deep-purple"
          height="4"
          indeterminate
      ></v-progress-linear>
    </template>

    <div class="d-flex justify-space-between" v-if="source !== 'home' && !addPatient && !loader">
      <v-col cols="auto">
        <v-btn  color="indigo-darken-3" v-on:click="addPatient=!addPatient">
          <v-icon>mdi-person-plus</v-icon>
          Ajouter un patient</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn color="indigo-darken-3" v-on:click="action=!action">
            <v-icon>mdi-camera</v-icon>
          Scanner
        </v-btn>
      </v-col>

      <h2 class="mt-2 grey--text" v-if="!addPatient"> Liste des patients</h2>

    </div>
    <v-divider class="w-100"></v-divider>

    <v-card
        class="mx-auto w-100"
        color="surface-light"
        max-width="900"
        v-if="!addPatient">
      <v-card-text>
        <v-text-field
            :loading="loader"
            append-inner-icon="mdi-magnify"
            v-model="search"
            density="compact"
            label="Search by name or medical record number "
            variant="solo"
            hide-details
            single-line
            @click:append-inner="getPatients"
        ></v-text-field>
      </v-card-text>
    </v-card>

    <v-divider class="w-100"></v-divider>

    <v-card v-if="action && !loader" class="w-fit">
      <v-dialog
          v-model="action"
          min-height="180"
          max-width="380">
        <QRCodeReader :source="'movie'" :action="action" @close="action=!action" @movie="gotoPatient"/>
      </v-dialog>
    </v-card>

    <v-container v-if="!addPatient && !loader" class="w-100" fluid>

      <v-row class="d-flex w-100">
        <v-col cols="12" sm="6" md="4" v-for="patient in patients" :key="patient.id" style="min-width: 25vw!important;" >
          <PatientBox class="w-100" :patient="patient"/>
        </v-col>
      </v-row>
      </v-container>
    <div class="text-center" v-if="!addPatient && loader">
      <v-pagination
          v-model="page"
          :length="total"
          @input="getPatients"
          rounded="circle"
      ></v-pagination>
    </div>

    <AddPatient v-if="addPatient && !loader" :add-action="true" @close="close" @action="getPatients" />


  </div>
</template>

<script>
import PatientBox from './PatientBox';
import CommonCRUDService from "@/services/CommonCRUD.service";
import AddPatient from "@/components/patients/AddPatient";
import AlertService from "@/services/alert.service";
import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";
import QRCodeReader from "@/components/commons/QRCodeReader";

export default {
  name: 'Patients',
  components :{
    PatientBox,
    AddPatient,
    commonBreadcrumbs,
    QRCodeReader
  },
  data: function (){
    return {
      search: null,
      action: false,
      dialogQRcode: false,
      patients: [],
      page: 1,
      pageSize: 7,
      addPatient:false,
      loader: false,
      loading: false,
      total: 0,
      items: [ {
        title: 'Accueil',
        disabled: false,
        href: '/',
      },
        {
          title: 'Patient',
          disabled: true,
          href: 'patients/',
        },],
    };
  },
  props: ['source'],

  async mounted(){
    await this.getPatients();
  },
  methods: {
    async getPatients() {
      try {
        this.loader = true;
        let url = 'patients/?page=' + this.page;
        if(this.search) {
          url = url + '&search=' + this.search ;
        }
        CommonCRUDService.getData(url).then(
            (response) => {
              console.log(response);
              this.addPatient = false;
              this.loader = false;
              if(response && response.data){
                this.patients = response.data;
              }
            },
            (error) => {
              this.patients = [];
              console.log(error)
              AlertService.error(error.message);
              this.loader = false;
            })
      }catch (error) {
        AlertService.error(error.message);
      }
    },

    onClick () {
      this.loading = true

      setTimeout(() => {
        this.loading = false
        this.loaded = true
      }, 2000)
    },

    close() {
      this.addPatient = false;
      this.getPatients();
    },
    gotoPatient(id){
      if (id) {
        this.action = false;
        this.$router.push({ path: '/patient/'+ id, params: { id } , replace: true  })
      }
    }

  },
  watch: {
    page() {
      this.getPatients();
    }
  }
}
</script>

<style>

</style>
