<template>
  <v-card>
    <div class="result">
      Result: {{idObject}}
    </div>
    <qrcode-stream @decode="onDecode"></qrcode-stream>

    <div class="card it-sp-bw it-al-row">
      <v-btn
          color="warning"
          variant="text"
          v-on:click="close">
        Annuler
      </v-btn>
    </div>

  </v-card>
</template>

<script>

import { QrcodeStream} from 'vue3-qrcode-reader'

export default {
  name: 'QRCodeReader',

  components: {
    QrcodeStream
  },

  props: ['source', 'action'],
  data(){
    return {
      idObject: 0,
    }
  },

  emits: ['close', 'actor', 'movie'],

  methods: {
    onDecode (decodedString) {
      console.log(decodedString);
      if (decodedString) {
        if (this.source ==='actor') {
          this.$emit('actor', decodedString);
        }
        if (this.source === 'movie') {
          this.$emit('movie', this.idObject);
        }
      }
    }
  },

}
</script>

<style scoped>
button {
  position: absolute;
  left: 10px;
  top: 10px;
}

.error {
  color: red;
  font-weight: bold;
}
</style>
