<template>
  <nav class="bg-blue-grey p-2" style="background-color: #455867 !important;">
    <div class="container mx-auto flex justify-between items-center">
      <div class="text-white text-2xl font-bold w-fit cur-p">
        <v-list-item prepend-icon="mdi-home" :to="'/'" class="text-white cur-p" title="Accueil" value="Accueil"></v-list-item>
        <!--
        <v-button v-on:click="goToMenu('/')">Accueil</v-button>
        -->
        </div>

      <div class="hidden md:flex space-x-2 item-menu-i">

        <v-list-item prepend-icon="mdi-account-group" :to="'/patients'" class="text-white cur-p" title="Patients" value="Patients"></v-list-item>
        <v-list-item prepend-icon="mdi-cogs" v-on:click="goToMenu('admin')" class="text-white cur-p"  title="Admin django" value="Admin django"></v-list-item>
      </div>
      <div class="md:hidden flex items-center">
        <button @click="toggleMenu" class="text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="isOpen" class="md:hidden">
      <v-list-item prepend-icon="mdi-home" v-on:click="goToMenu('/')" class="text-white cur-p" title="Accueil" value="Accueil"></v-list-item>
      <v-list-item prepend-icon="mdi-account-group" :to="'/patients'" class="text-white cur-p" title="Patients" value="Patients"></v-list-item>
      <v-list-item prepend-icon="mdi-cogs" v-on:click="goToMenu('admin')" class="text-white cur-p"  title="Admin django" value="Admin django"></v-list-item>
    </div>
  </nav>
</template>

<script>

import {ADMIN_URL} from "@/config/dev.env";

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    goToMenu(action){
      if (action === 'admin'){
        this.isOpen = false;
        window.open(ADMIN_URL, '_blank');

      }else {
        this.$router.replace(action);
        this.isOpen = !this.isOpen;
      }
    }
  }
};
</script>

<style scoped>
/* Add any additional custom styles here */


</style>
