<template>
  <v-footer
      dark
      padless
  >
    <v-card
        flat
        tile
        class="pink white--text text-center"
    >
      <v-card-text>
        <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0 w-100 ma-auto">
        Vuetify is a no design skills required Open Source UI Component Frame
        work for Vue. It provides you with all of the t.
        Vuetify is a no design skills required Open Source UI Component Frame
        work for Vue. It provides you with all of the t.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Bah Aliou</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "NavFooter",
  data: () =>({
    icons:[
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram'
    ]
  })
}
</script>

<style>

</style>
