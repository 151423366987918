<template>

  <div class="w100 it-al-col">
    <div class="w90 m-auto item-ali-center mt-2">
      <commonBreadcrumbs class="w-100" :items="items" />
    </div>
    <v-divider class="w-100 m-0"></v-divider>

    <div class="min-h-fit w95 ma-auto mt-2 mb-2 item-ali-center">
      <template v-if="loader">
        <v-progress-linear
            :active="loader"
            color="deep-purple"
            height="4"
            indeterminate
        ></v-progress-linear>
      </template>
      <v-container class="it-sp-bw w-100 m-0 d-flex flex-wrap" v-if="!loader">

        <v-card v-if="!dialog"  class="min-w39 h-fit width-describe" sm="12" max-width="384">

          <v-card-title v-tooltip="'Nom du patient'" class="text-h5 w-100">{{(patient)? patient.firstName :''}} {{(patient)? patient.lastName :''}}</v-card-title>
          <v-divider class="w-100 m-0"></v-divider>

          <div class="px-4 mb-2 w-100 it-al-row it-sp-bw">
            <span>Age: {{(patient)? patient.age :''}}</span>
            <span>medicalRecordNumber: {{(patient)? patient.medicalRecordNumber :''}}</span>
          </div>
          <v-divider class="m-0 w-100"> </v-divider>

          <v-card v-if="patient"
                  class="w-100 ma-auto justify-space-between flex-row it-al-row p-2">
            <div class="m-auto w-fit" style="margin-left: 0.2rem!important;">
              <v-btn class="w-fit p-2"
                     color="indigo-darken-3"
                     text="Modifier"
                     block
                     v-tooltip="'Modficier ce patient'"
                     @click="editPatient(patient)">
                <v-icon
                    class="me-2 size15"
                    size="small">
                  mdi-pencil
                </v-icon>Modifier</v-btn>
            </div>
            <div class="m-auto w-fit">
              <v-icon  class="cur-p size5 m-auto"
                       v-tooltip="'Génerer un QrCode pour le patient'"
                       style="background: #fef4e4!important;"
                       v-on:click="dialogQRcode=!dialogQRcode">mdi-qrcode-edit</v-icon>
            </div>
          </v-card>
        </v-card>

        <v-expansion-panels class="ma-auto" v-if="!dialog" >
          <v-expansion-panel
              v-for="i in 3"
              :key="i"
              class="m-1"
          >
            <v-expansion-panel-title expand-icon="mdi-menu-down">

              <v-icon  :icon="(i === 1) ? 'mdi-heart-pulse' :  ((i===3)? 'mdi-blood-bag' : 'mdi-coolant-temperature')"></v-icon>

              <v-title>  &nbsp;{{ (i === 1) ? 'Heart rate': ((i===2)? 'Temperature': 'Blood pressure') }}</v-title>
            </v-expansion-panel-title>
            <v-expansion-panel-text>

              <div v-if="i===1 && vital_chart && vital_chart.chart_heart" class="w100 it-al-row min-h40" >
                <v-card
                    class="mx-auto min-w39"
                    max-width="100"
                    hover
                >
                  <v-card-item>
                    <v-card-title>
                      Heart rate
                    </v-card-title>

                    <v-card-subtitle>
                      <span v-for="item in patient.vitals.heartRate"  :key="item"> {{item}}, &nbsp;</span>
                    </v-card-subtitle>
                  </v-card-item>

                </v-card>
                <v-card class="min-w59">
                  <Line
                      id="my-chart-id"
                      :options="chartOptions"
                      :data="vital_chart.chart_heart"
                  />
                </v-card>
              </div>

              <div v-if="i===2 && vital_chart && vital_chart.chart_temp" class="w100 it-al-row min-h40 mt-2" >
                <v-card
                    class="mx-auto min-w39"
                    max-width="100"
                    hover
                >
                  <v-card-item>
                    <v-card-title>
                      Temperature
                    </v-card-title>

                    <v-card-subtitle>
                      <span v-for="item in patient.vitals.temperature"  :key="item"> {{item}}, &nbsp;</span>
                    </v-card-subtitle>
                  </v-card-item>

                </v-card>
                <v-card class="min-w59">
                <Line
                    id="my-chart-id"
                    :options="chartOptions"
                    :data="vital_chart.chart_temp"
                />
                </v-card>
              </div>

              <div v-if="i===3 && vital_chart && vital_chart.chart_oxy" class="w100 it-al-row min-h40 mt-2" >
                <v-card
                    class="mx-auto min-w39"
                    max-width="100"
                    hover
                >
                  <v-card-item>
                    <v-card-title>
                      Blood pressure
                    </v-card-title>

                    <v-card-subtitle>
                      <span v-for="item in patient.vitals.bloodPressure"  :key="item.diastolic"> {{item.diastolic}}, &nbsp;</span>
                    </v-card-subtitle>
                  </v-card-item>

                </v-card>
                <v-card class="min-w59">
                <Line
                    id="my-chart-id"
                    :options="chartOptions"
                    :data="vital_chart.chart_oxy"
                />
                </v-card>
              </div>


            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>


        <AddPatient v-if="dialog" :add-action="false" :patient="patient"  @close="close" @action="getpatientById" />

        <v-dialog
            v-model="dialogQRcode"
            max-width="290"
            v-if="dialogQRcode && patient && patient.id">
          <QRGenerator :add-action="this.addAction"  :source="'patient'"  :cur-code-info="patient.id" :patient="patient"  @close="close"/>
        </v-dialog>

      </v-container>
    </div>


  </div>

</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import AddPatient from "@/components/patients/AddPatient";
import AlertService from "@/services/alert.service";
import QRGenerator from "@/components/commons/QRGenerator";
import commonBreadcrumbs from "@/components/commons/commonBreadcrumbs";

import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  name:"Describepatient",
components: {
  AddPatient,
  QRGenerator,
  commonBreadcrumbs,
  Line
},
   data() {
    return {
      loader: false,
      patient: null,
      dialog: false,
      updatePatient: false,
      editedItem: null,
      addAction: false,
      dialogQRcode: false,
      items: [ {
        title: 'Accueil',
        disabled: false,
        href: '/',
      },
        {
          title: 'Patients',
          disabled: true,
          href: 'patients/',
        },
        {
          title: 'Details',
          disabled: true,
          href: 'patients/',
        }],
      vital_chart: null,

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
  },
   watch: {"$route.params.id": {
      handler() {
        this.getPatientById();
      },
      immediate: true,
    },
  },
   computed: {
  },
  created () {
    this.getPatientById();
  },
  methods: {
    async getPatientById() {
      if (this.$route && this.$route.params) {
        const id = this.$route.params.id;
        const url = `patient/${id}/`
        this.loader = true;
        CommonCRUDService.getData(url).then(
            (response) => {
              console.log(response);
              this.loader = false;
              if (response && response.data) {
                this.patient = response.data;
                if (response.data.vitals && response.data.vitals.vital_chart) {
                  this.vital_chart = response.data.vitals.vital_chart;
                }
              }
            },
            (error) => {
              this.loader = false;
              console.log(error);
              AlertService.error(error.message);
            })

      }
    },
    editPatient(item) {
      this.patient=item
      this.dialog = true
    },
    close() {
      this.dialog = false;
      this.dialogGrade = false;
      this.dialogQRcode = false;
    }
  },
  
}
</script>
<style>
.rating-values {
  width: 25px;
}
</style>
