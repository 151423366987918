import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home";
import ManagePatient from "@/components/patients/ManagePatient";
import DescribePatient from "@/components/patients/DescribePatient";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/patients/',
        name: 'ManagePatient',
        component: ManagePatient
    },
    {
        path:"/patient/:id",
        name: 'DescribePatient',
        component: DescribePatient
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

