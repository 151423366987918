<template>
   <div class="min-h-fit w90 m-auto item-ali-center">
     <Patients class="w-100" />
   </div>
</template>

<script>

  import Patients from "@/components/patients/Patients";

  export default {
    name: 'ManagePatient',
    components: {
      Patients
    }
    
  }
</script>
