<template>
  <v-breadcrumbs :items="items" class="font-weight-bold d-flex flex-wrap mb-0">
    <template v-slot:title="{ item }">
      {{ item.title }}
    </template>
  </v-breadcrumbs>

</template>

<script>
export default {
  name: "commonBreadcrumbs",
  props: ['items'],
  emits: ['close', 'action'],
}
</script>

<style scoped>

</style>
