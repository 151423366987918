import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from "./stores";
import router from './router'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueNavigationBar from 'vue-navigation-bar';
import VueQRCodeComponent from 'vue-qrcode-component'
import PrimeVue from 'primevue/config';

import 'vue-navigation-bar/dist/vue-navigation-bar.css';
import './main.css';
import 'vue-select/dist/vue-select.css';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme from PrimeVue themes folder
import 'primevue/resources/primevue.min.css'; // PrimeVue CSS
import 'primeicons/primeicons.css'; // Icons

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .component('qr-code', VueQRCodeComponent)
  .component('vue-navigation-bar', VueNavigationBar)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
