import Swal from "sweetalert2";

const AlertService = {
  success(message) {
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: message,
      showConfirmButton: false,
      timer: 1500
    });
  },
  error(message) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: message,
      showConfirmButton: false,
      timer: 1500
    });
  },
};

export default AlertService;
