<template>
  <v-hover
  v-slot="{hover}"
  open-delay="200"
  >
  <v-card :elevation="hover ? 16:2" :class="{'on-hover' : hover}" class="w-100">

      <v-card-title class="subtitle-2" v-tooltip="'Patient name'">

        <v-icon  :icon="'mdi-account-circle'"></v-icon>

        {{(patient) ? patient.firstName: ''}} {{(patient) ? patient.lastName: ''}}</v-card-title>
    <v-divider class="w-100"></v-divider>

    <v-expansion-panels class="mb-6">
      <v-expansion-panel
          v-for="i in 3"
          :key="i"
          class="m-1"
      >
        <v-expansion-panel-title expand-icon="mdi-menu-down">

          <v-icon  :icon="(i === 1) ? 'mdi-heart-pulse' :  ((i===3)? 'mdi-blood-bag' : 'mdi-coolant-temperature')"></v-icon>

          <v-title>  &nbsp;{{ (i === 1) ? 'Heart rate': ((i===2)? 'Temperature': 'Blood pressure') }}</v-title>
        </v-expansion-panel-title>
        <v-expansion-panel-text>

          <v-list-tile-sub-title v-if="i===1 && patient && patient.vitals && patient.vitals.heartRate">
            <span v-for="item in patient.vitals.heartRate"  :key="item"> {{item}}, &nbsp;</span>
          </v-list-tile-sub-title>

          <v-list-tile-sub-title v-if="i===2 && patient && patient.vitals && patient.vitals.temperature">
            <span v-for="item in patient.vitals.temperature"  :key="item"> {{item}}, &nbsp;</span>
          </v-list-tile-sub-title>

          <v-list-tile-sub-title v-if="i===3 && patient && patient.vitals && patient.vitals.bloodPressure">
            <span v-for="item in patient.vitals.bloodPressure"  :key="item.diastolic"> {{item.diastolic}}, &nbsp;</span>
          </v-list-tile-sub-title>

        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>


      <v-card-text class="it-al-row">

<!--        <v-rating v-model="rating" class="ma-auto" v-tooltip="'Moyenne de notation'">
          <template v-slot:item="props">
            <v-icon
                :color="(props.isFilled && patient && this.patient.grade && this.patient.grade.grade_avg>0) ? colors[props.index] : 'grey-lighten-1'"
                size="large"
                @click="props.onClick"
            >
              {{ props.isFilled ? 'mdi-star-circle' : 'mdi-star-circle-outline' }}
            </v-icon>
          </template>
        </v-rating>
        <div> &nbsp; | &nbsp;</div>-->
        <v-row align="center" class="mx-0">
          <v-btn color="orange" v-on:click="goToPage(patient)" v-tooltip="'Détails du patient'">
            <v-icon>mdi-patient-search</v-icon>
            Détails
          </v-btn>
        </v-row>
      </v-card-text>
  </v-card>

  </v-hover>
</template>

<script>
export default {
  name: 'PatientBox',
  open: ['Users'],
  data: () => ({
    colors: ['green', 'purple', 'orange', 'indigo', 'red'],
  }),
  props: {
    patient: {
      required: true,
    }
  },
  computed: {
    rating() {
      return (this.patient && this.patient.grade)? this.patient.grade.grade_avg: null;
    }
  },
  methods: {
    goToPage(patient) {
      if (patient) {
        this.$router.push({ path: '/patient/'+patient.id, params: { id: patient.id } , replace: true  })
      }
    }
  }
}
</script>

<style>

</style>
