import axios from "axios";
import {API_URL} from "@/config/dev.env";

export default axios.create({
    baseURL: API_URL,
    withCredentials: false,
    /*headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }*/
});
