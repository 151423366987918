<script>
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

export default {
  props: ['addAction', 'actor', 'movie', 'source', 'curCodeInfo'],
  emits: ['close', 'action'],
  data() {
    return {
      hello: "hello",
      dialog: false,
      currentItem: undefined,
      dialogProd: false,
    };
  },
  methods: {
    editQrCode(item) {
      this.currentItem = item;
      this.dialog = true;
    },
    openDialog() {
    },

    close() {
      this.dialog = false;
      this.dialogProd = false;
      this.$emit('close', true);
    },
    generatePDF() {
      const pdfTable = document.getElementById('divToPrint');
      const html = htmlToPdfmake(pdfTable.innerHTML);
      const documentDefinition = {content: html};
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).open();
      this.$emit('close', true);
    }
  },
}
</script>>
<style>

</style>
<template>
  <v-card class="p-2 w80 m-auto-i" style="min-width: 30vw">
    <v-card-title class="text-h6 m-auto">
      Generation du QrCode
    </v-card-title>

    <hr class="m-0"/>

    <div id="divToPrint" style="margin: auto!important;">

      <v-layout  class="m-auto-i">
        <v-card-text>
          <strong v-if="this.source === 'movie' && movie">Film : {{movie.title}}</strong>
          <strong v-if="this.source === 'actor' && actor">Actor : {{ actor.first_name }} {{ actor.last_name }}</strong>
        </v-card-text>
      </v-layout>
      <hr class="m-0"/>

      <v-card class="mb-1" style="margin: auto!important;">
        <qr-code :text="curCodeInfo" style="margin: auto!important;"></qr-code>
      </v-card>

    </div>

    <div class="card it-sp-bw it-al-row mt-2">
      <v-btn
          color="warning"
          variant="text"
          v-on:click="close">
        Annuler
      </v-btn>
      <v-btn
          color="success"
          class="mr-4 cur-p"
          variant="text"
          @click="generatePDF()">
        Generer
      </v-btn>
    </div>

  </v-card>

</template>
