// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { VBtn } from 'vuetify/components/VBtn'
import defaults from './defaults'
import theme from './theme'

export default createVuetify(
    {
        components,
        directives,
        aliases: {
            MyButton: VBtn,
        },
        theme,
        defaults,
    }
)
