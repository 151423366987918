<template>
  <v-app>
    <v-main>
      <NavBar/>

      <v-content>
        <router-view></router-view>
      </v-content>
      <NavFooter />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/commons/NavBar";
import NavFooter from "@/components/commons/NavFooter";

export default {
  name: 'App',

  components: {
    NavFooter,
    NavBar,
  },

  data: () => ({
    //
  }),
}
</script>

<style lang="scss">
.w80{
  width: 80%!important;
}
.w85{
  width: 85%!important;
}
.w90{
  width: 90%!important;
}

.w100{
  width: 100%!important;
}
.w95{
  width: 95%!important;
}
.min-w49{
  min-width: 49%!important;
}
.min-w39{
  min-width: 39%!important;
}
.min-w59{
  min-width: 59%!important;
}
.min-w70{
  min-width: 70%!important;
}
.min-h90{
  min-height: 90%!important;
}

.min-h40 {
 min-height: 35vh!important;
}
.min-h92{
  min-height: 92%!important;
}
.min-h94{
  min-height: 94%!important;
}

.bc-none {
  background: none!important;
}


.it-al-row {
  display: flex!important;
  flex-direction: row!important;
}

.it-al-col {
  display: flex!important;
  flex-direction: column!important;
}
.it-sp-bw{
  display: flex;
  justify-content: space-between!important;
}

.h-max {
  height: max-content!important;
}
.w-max{
  width: max-content!important;
}
.h-heri {
  height: inherit!important;
}


.v-navigation-drawer__content {
  flex: 0 1 auto;
  height: 100%;
  width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #343a40!important;
  color: white!important;
}

.item-ali-center {
  display: flex;
  justify-content: center!important;
}
.marg1 {
  margin: 1%!important;
}

.w50{
  width: 50%!important;
}
.w80{
  width: 80%!important;
}
.m-auto{
  margin: auto!important;
}

.m-auto-i{
  margin: auto!important;
}

.mt-b-auto {
  margin-bottom: auto!important;
  margin-top: auto!important;
}
.size12 {
  font-size: 1.2rem!important;
}
.size1 {
  font-size: 1rem!important;
}

.size15 {
  font-size: 1.5rem!important;
}
.size2 {
  font-size: 2rem!important;
}
.size3 {
  font-size: 3rem!important;
}
.cur-p{
  cursor: pointer!important;
}
.size5 {
  font-size: 5rem!important;
}
.t-al-lef{
  text-align: left!important;
}

.t-al-center{
  text-align: center!important;
}

.min-h80{
  min-height: 80vh!important;
}

.item-menu-i{
  .v-list-item__prepend {
    align-items: center;
    align-self: center;
    display: block!important;
  }
}
.w-fit{
  width: fit-content!important;
}

.h-fit {
  height: fit-content!important;
}
.min-h-fit {
  min-height: fit-content!important;
}
@import '~vuetify/dist/vuetify.min.css';

@import 'primevue/resources/primevue.min.css';
@import 'primeicons/primeicons.css';

.p-multiselect-token {
  border-radius: 0.2rem !important;
  min-width: 10vw!important;
  padding: 0.2rem!important;
  justify-content: space-between!important;
  margin: 0.5% !important;
}

@media screen and (max-width: 1024px)
{
  .width-describe {
    min-width: 99%!important;
  }
}
</style>
