<template>
  <v-card class="w-100">
    <v-card-title>
      <span class="text-h5" > {{ (addAction) ? 'Ajout d\'un patient' : 'Modification d\'un patient'}}</span>
    </v-card-title>

    <v-form v-model="valid"
            ref="patient"
            class="p-2">
      <v-text-field
          v-model="patient.firstName"
          label="First Name"
          :rules="[v => !!v || 'First name is required']"
          required
      ></v-text-field>

      <v-text-field
          v-model="patient.lastName"
          label="Last Name"
          :rules="[v => !!v || 'Last name is required']"
          required
          class="mt-1"
      ></v-text-field>

      <v-text-field
          v-model="patient.age"
          label="Age"
          :rules="[v => !!v || 'Age is required']"
          required
          type="number"
          class="mt-1"
      ></v-text-field>

      <v-text-field
          v-model="patient.medicalRecordNumber"
          label="Medial record number"
          :rules="[v => !!v || 'Medial record number is required']"
          required
          class="mt-1"
      ></v-text-field>


      <v-card class="mb-4">
        <v-card-title>Heart Rate</v-card-title>
        <v-card-text>
          <v-row v-for="(item, index) in patient.vitals.heartRate" :key="index">
            <v-col cols="10">
              <v-text-field
                  v-model="patient.vitals.heartRate[index]"
                  label="Heart Rate"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removeItem('heartRate', index)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addItem('heartRate')">Add Heart Rate</v-btn>
        </v-card-text>
      </v-card>


      <v-card class="mb-4">
        <v-card-title>Temperature</v-card-title>
        <v-card-text>
          <v-row v-for="(item, index) in patient.vitals.temperature" :key="index">
            <v-col cols="10">
              <v-text-field
                  v-model="patient.vitals.temperature[index]"
                  label="Temperature"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removeItem('temperature', index)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addItem('temperature')">Add Temperature</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>Blood Pressure</v-card-title>
        <v-card-text>
          <v-row v-for="(item, index) in patient.vitals.bloodPressure" :key="index">
            <v-col cols="5">
              <v-text-field
                  v-model="patient.vitals.bloodPressure[index].systolic"
                  label="Systolic"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  v-model="patient.vitals.bloodPressure[index].diastolic"
                  label="Diastolic"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removeItem('bloodPressure', index)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addItem('bloodPressure')">Add Blood Pressure</v-btn>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>Oxygen saturation</v-card-title>
        <v-card-text>
          <v-row v-for="(item, index) in patient.vitals.oxygenSaturation" :key="index">
            <v-col cols="10">
              <v-text-field
                  v-model="patient.vitals.oxygenSaturation[index]"
                  label="Text 3"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="removeItem('oxygenSaturation', index)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn @click="addItem('oxygenSaturation')">Add oxygen saturation</v-btn>
        </v-card-text>
      </v-card>

    </v-form>


    <div class="card it-sp-bw it-al-row pa-2">

      <v-btn color="warning cur-p"  v-on:click="close">
        <v-icon>mdi-close-circle</v-icon>
        Annuler
      </v-btn>
      <v-btn color="indigo-darken-3 cur-p" v-on:click="savePatient()">
        <v-icon>mdi-content-save</v-icon>
        Enregistrer
      </v-btn>
    </div>


  </v-card>

</template>
<script>
import CommonCRUDService from "@/services/CommonCRUD.service";
import AlertService from "@/services/alert.service";

export default {

  data() {
    return {
      patient: {
        firstName: '',
        lastName: '',
        age: '',
        medicalRecordNumber: '',
        vitals: {
          heartRate: [],
          temperature: [],
          bloodPressure: [],
          oxygenSaturation: [],
        },
      },
      isEditing: false,
    };
  },
  props: ['addAction', 'content'],
  emits: ['close', 'action'],

  methods: {
    close() {
      this.initFileld();
      this.$emit('close', true)
    },

    async savePatient() {
      try {
        if (this.addAction) {
          const url = 'patients/';
          console.log(this.patient);
          await CommonCRUDService.postData(this.patient, url);
          AlertService.success('Ce patient a été ajoute avec succes');
          this.close();
        } else if(this.patient && this.patient.id) {
          const url = 'patient/' + this.patient.id + '/update/';
          await CommonCRUDService.updateData(this.patient, url);
          AlertService.success('Ce patient a été modifié avec succes');
        //  this.$emit('action', true)
          this.close();
        }
      } catch (err) {
        console.log(err);
        AlertService.error(err.message);
      }
    },
    initFileld() {

    },
    addItem(field) {
      if (field === 'heartRate' || field === 'temperature' || field === 'oxygenSaturation') {
        this.patient.vitals[field].push(null);
      } else if (field === 'bloodPressure') {
        this.patient.vitals[field].push({ systolic: null, diastolic: null });
      }
    },
    removeItem(field, index) {
      this.patient?.vitals[field]?.splice(index, 1);
    },

  },

  created() {
    this.initFileld()
  },
  computed: {

  },

}
</script>
